.container-wrapper {
  width: 100%;
  height: 100%;
}
#name {
  text-align: center;
  width: 100%;
  margin-top: 10%;
}
#title {
  width: 100%;
  text-align: center;
}

.link-wrapper {
  text-align: center;
  width: 100%;
  #github,
  #linkedin,
  #twitter {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
  }
}

@media screen and (max-width: 992px) {
  #name {
    margin-top: 150px;
  }
  #title {
    margin-top: 20px;
  }
  .link-wrapper {
    #github,
    #linkedin,
    #twitter {
      margin: 10px;
    }
  }
}
